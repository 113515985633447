import {
  GeolocationControl,
  Map,
  Placemark,
  RulerControl,
} from "@pbe/react-yandex-maps";
import { motion } from "framer-motion";
import phone from "../assets/phone.png";
import geotag from "../assets/geotag.png";
import clock from "../assets/clock.png";
export const Contacts = () => {
  return (
    <section
      datatype="contacts-extensible"
      id="contacts-extensible"
      className="s-contacts-extensible-type-1 sb-block s-contacts-extensible-type-1_right sb-text-dark"
    >
      <div className=" sb-background" style={{ background: "#ffffff" }}></div>
      <div className="s-contacts-extensible-type-1__map sb-m-1-bottom  ">
        <div
          className="s-contacts-extensible-type-1__map-data map-data-js"
          data-map='{"markers":[{"name":"Пионерский переулок, 2","coords":["55.386333","36.72321"],"description":"Наро-Фоминск, Московская область, Россия"}]}'
        >
          <Map
            defaultState={{
              center: [55.386333, 36.72321],
              zoom: 16,
              controls: ["fullscreenControl", "zoomControl"],
            }}
            modules={["control.FullscreenControl", "control.ZoomControl"]}
            width={"100%"}
            height={"100%"}
          >
            <GeolocationControl options={{ float: "left" }} />
            <RulerControl options={{ float: "right" }} />
            <Placemark
              defaultGeometry={[55.386333, 36.72321]}
              properties={{
                iconContent: "Пионерский переулок, 2",
              }}
              options={{ preset: "islands#blueStretchyIcon" }}
            />
          </Map>
        </div>
      </div>
      <div className="sb-container sb-m-1-bottom s-contacts-extensible-type-1__content">
        <div className="sb-row">
          <motion.div
            initial={{ opacity: 0, transform: "translate3d(200px, 0, 0)" }}
            whileInView={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="sb-col_lg-5 sb-col_md-5 sb-col_sm-12 sb-col_xs-12 sb-m-clear-bottom  sb-col-offset_lg-7 sb-col-offset_md-7 "
          >
            <h2 className="sb-font-h2 sb-font-title sb-m-12-bottom">
              Контакты
            </h2>
            <div className="s-contacts-extensible-type-1__items">
              <div className="s-contacts-extensible-type-1__item sb-m-7-top sb-m-clear-bottom">
                <div className="s-contacts-extensible-type-1__icon-wrapper ">
                  <div className="s-contacts-extensible-type-1__icon">
                    <img
                      className="sb-image-crop sb-image-crop_loaded lazy"
                      src={geotag}
                      alt="Изображение гео-тега"
                    />
                  </div>
                </div>
                <div className="s-contacts-extensible-type-1__info sb-m-clear-bottom">
                  <h5 className="sb-font-h5 sb-font-title sb-m-21-bottom">
                    Адрес:
                  </h5>
                  <div className="sb-text-opacity sb-font-p3">
                    <font style={{ color: "#111111", letterSpacing: 0.3 }}>
                      Московская область, г.&nbsp;Наро-Фоминск, <br />
                      Пионерский пер, д.&nbsp;2,
                    </font>
                  </div>
                </div>
              </div>
              <div className="s-contacts-extensible-type-1__item sb-m-7-top sb-m-clear-bottom">
                <div className="s-contacts-extensible-type-1__icon-wrapper ">
                  <div className="s-contacts-extensible-type-1__icon">
                    <img
                      className="sb-image-crop sb-image-crop_loaded lazy"
                      src={clock}
                      alt="Изображение часов"
                    />
                  </div>
                </div>
                <div className="s-contacts-extensible-type-1__info sb-m-clear-bottom">
                  <h5 className="sb-font-h5 sb-font-title sb-m-21-bottom">
                    Часы работы:
                  </h5>
                  <div className="sb-text-opacity sb-font-p3">
                    <font style={{ color: "#111111", letterSpacing: 0.3 }}>
                      Пон—Пятн: 9:00—19:00
                      <br />
                      Суббота: 9:00—15:00
                    </font>
                    <div style={{ userSelect: "auto" }}>
                      <font style={{ color: "#111111", letterSpacing: 0.3 }}>
                        Воскресенье: выходной
                      </font>
                    </div>
                  </div>
                </div>
              </div>
              <div className="s-contacts-extensible-type-1__item sb-m-7-top sb-m-clear-bottom">
                <div className="s-contacts-extensible-type-1__icon-wrapper ">
                  <div className="s-contacts-extensible-type-1__icon">
                    <img
                      className="sb-image-crop sb-image-crop_loaded lazy"
                      src={phone}
                      alt="Изображение телефона"
                    />
                  </div>
                </div>
                <div className="s-contacts-extensible-type-1__info sb-m-clear-bottom">
                  <h5 className="sb-font-h5 sb-font-title sb-m-21-bottom">
                    Телефон:
                  </h5>
                  <div className="sb-text-opacity sb-align-start sb-font-p3">
                    <font
                      style={{
                        color: "rgb(17, 17, 17)",
                        userSelect: "auto",
                        letterSpacing: 0.3,
                      }}
                    >
                      <a
                        style={{ userSelect: "auto" }}
                        data-link-id="0"
                        className="sb-link"
                        href="tel:8-968-933-33-03"
                      >
                        8-968-933-33-03
                      </a>
                      <br />
                      <a
                        style={{ userSelect: "auto", letterSpacing: 0.3 }}
                        data-link-id="1"
                        className="sb-link"
                        href="mailto:s9689333303@mail.ru"
                      >
                        s9689333303@mail.ru
                      </a>
                    </font>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};
