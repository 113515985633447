import { useEffect } from "react";
import { useScrollWindowListener } from "./useScrollWindowListener";

export const useVisibleTopLink = (bool, reducer) => {
  const [pageY] = useScrollWindowListener();

  useEffect(() => {
    if (pageY <= 300) {
      reducer(false);
    } else if (pageY > 300 && !bool) {
      reducer(true);
    }
  }, [pageY]);
};
