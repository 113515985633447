import { motion } from "framer-motion";
export const Callbacks = () => {
  return (
    <section
      datatype="reviews"
      id="reviews-2"
      className="s-reviews-type-3 sb-text-dark sb-padding-top_l sb-padding-bottom_l"
    >
      <div className=" sb-background" style={{ background: "#fff" }}></div>
      <div className="sb-container sb-m-clear-bottom">
        <motion.div
          initial={{ opacity: 0, transform: "translate3d(0, 200px, 0)" }}
          whileInView={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="sb-row sb-m-6-bottom"
        >
          <h2 className="sb-col_lg-10 sb-col_md-12 sb-col_sm-12 sb-col_xs-12 sb-font-h2 sb-font-title">
            <font style={{ color: "rgb(0,0,0)" }}>
              <b>Отзывы посетителей</b>
            </font>
          </h2>
        </motion.div>
        <div className="sb-row sb-m-6-top-minus">
          <motion.div
            initial={{ opacity: 0, transform: "translate3d(0, 200px, 0)" }}
            whileInView={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="sb-m-6-top sb-col_lg-6 sb-col_md-6 sb-col_sm-12 sb-col_xs-12"
          >
            <div className="sb-m-clear-bottom">
              <div className="s-reviews-type-3__top sb-m-18-bottom sb-m-clear-bottom sb-m-clear-right">
                <div className="sb-m-clear-bottom">
                  <h3 className="sb-font-h4 sb-m-22-bottom">Анна Борц</h3>
                </div>
              </div>
              <div className="sb-font-p2">
                От занятий получила только положительный эффект — не болела
                спина, не было отеков, было много сил, не чувствовался «тяжелый»
                живот даже на последних сроках беременности. Всем беременным
                подругам рекомендую йогу для беременных.
              </div>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, transform: "translate3d(0, 200px, 0)" }}
            whileInView={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="sb-m-6-top sb-col_lg-6 sb-col_md-6 sb-col_sm-12 sb-col_xs-12"
          >
            <div className="sb-m-clear-bottom">
              <div className="s-reviews-type-3__top sb-m-18-bottom sb-m-clear-bottom sb-m-clear-right">
                <div className="sb-m-clear-bottom">
                  <h3 className="sb-font-h4 sb-m-22-bottom">Юлия</h3>
                </div>
              </div>
              <div className="sb-font-p2">
                Лекарь Александр в своей Здравнице - Студии красоты (по
                домашнему уютной) творит чудесное оздоровление для Души и Тела!
                Моё тело ему полностью доверяло, поэтому получилось тотально
                расслабиться в руках у мастера.
                <div>
                  Он все мои зажимы и проблемные места очень аккуратно, без
                  напряжения, как бы растворил. Моё тело стало как горячий
                  пластилин.
                </div>
                <div>
                  Очень приятный, профессиональный массаж., и чувство, что
                  мастер любит своё дело! Это как в фильме "Аватар" говорили: -
                  «я тебя вижу» - вот так же и Александр видел моё тело своими
                  руками! У него отличное образование доктора и шикарный
                  практический стаж — Александр так интересно и понятно может
                  рассказать о причинах недугов и посоветовать-если спросите!
                  Благодарность Мастеру-Лекарю! ЗдОрово!
                  <div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, transform: "translate3d(0, 200px, 0)" }}
            whileInView={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="sb-m-6-top sb-col_lg-6 sb-col_md-6 sb-col_sm-12 sb-col_xs-12"
          >
            <div className="sb-m-clear-bottom">
              <div className="s-reviews-type-3__top sb-m-18-bottom sb-m-clear-bottom sb-m-clear-right">
                <div className="sb-m-clear-bottom">
                  <h3 className="sb-font-h4 sb-m-22-bottom">Евгений Наумов</h3>
                </div>
              </div>
              <div className="sb-font-p2">
                Хотим выразить огромную благодарность Александру и Ольге за
                высокий профессионализм. Прошли с мужем курс общего массажа.
                <div>
                  Остались очень довольны, а главное , почувствовали улучшение
                  состояния. Александр мастер своего дела.
                </div>
                <div>
                  А ещё решила побаловать себя косметическими процедурами. То,
                  что делает с вашим лицом Ольга, просто чудо. За курс личико
                  подтянулось, подпиталось и получило классный массаж.
                </div>
                <div>
                  В салоне очень приятная атмосфера.
                  <div>
                    Александр и Ольга, спасибо Вам ! Удачи и процветания.
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, transform: "translate3d(0, 200px, 0)" }}
            whileInView={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="sb-m-6-top sb-col_lg-6 sb-col_md-6 sb-col_sm-12 sb-col_xs-12"
          >
            <div className="sb-m-clear-bottom">
              <div className="s-reviews-type-3__top sb-m-18-bottom sb-m-clear-bottom sb-m-clear-right">
                <div className="sb-m-clear-bottom">
                  <h3 className="sb-font-h4 sb-m-22-bottom">Ольга</h3>
                </div>
              </div>
              <div className="sb-font-p2">
                Прошла курс антицеллюлитного массажа. Очень порадовал результат.
                Однозначно рекомендую.<div>Спасибо огромное!</div>
              </div>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, transform: "translate3d(0, 200px, 0)" }}
            whileInView={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="sb-m-6-top sb-col_lg-6 sb-col_md-6 sb-col_sm-12 sb-col_xs-12"
          >
            <div className="sb-m-clear-bottom">
              <div className="s-reviews-type-3__top sb-m-18-bottom sb-m-clear-bottom sb-m-clear-right">
                <div className="sb-m-clear-bottom">
                  <h3 className="sb-font-h4 sb-m-22-bottom">Екатерина </h3>
                </div>
              </div>
              <div className="sb-font-p2">
                Александра мне порекомендовали мои знакомые!!!
                <div>
                  У меня сильно болела спина, после первого сеанса у меня
                  по-тихоньку прошла спина, массаж понравился. Взяла курс и не
                  пожалела.
                </div>
              </div>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, transform: "translate3d(0, 200px, 0)" }}
            whileInView={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="sb-m-6-top sb-col_lg-6 sb-col_md-6 sb-col_sm-12 sb-col_xs-12"
          >
            <div className="sb-m-clear-bottom">
              <div className="s-reviews-type-3__top sb-m-18-bottom sb-m-clear-bottom sb-m-clear-right">
                <div className="sb-m-clear-bottom">
                  <h3 className="sb-font-h4 sb-m-22-bottom">Евгения</h3>
                </div>
              </div>
              <div className="sb-font-p2">
                Александр - прекрасный специалист и профессионал своего дела.
                Огромное спасибо ему за исцеление моей спины.
                <div>Обязательно приду ещё!</div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};
