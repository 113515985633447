export const classicData = [
  {
    name: "Массаж воротниковой зоны (20 минут)",
    price: "800 ₽",
  },
  {
    name: "Массаж воротниковой зоны+верхних конечностей (40минут)",
    price: "1300 ₽",
  },
  {
    name: "Массаж шейно-грудной зоны (30минут)",
    price: "1100 ₽",
  },
  {
    name: "Массаж пояснично-крестцовой зоны + ягодиц (30 минут)",
    price: "1100 ₽",
  },
  {
    name: "Массаж пояснично-крестовой зоны (20минут)",
    price: "700 ₽",
  },
  {
    name: "Массаж пояснично-крестцовой зоны+ягодиц+нижних конечностей (50минут)",
    price: "1700 ₽",
  },
  {
    name: "Массаж всех отделов позвоночника (40минут)",
    price: "1500 ₽",
  },
  {
    name: "Общий массаж тела (1час 20минут)",
    price: "2500 ₽",
  },
  {
    name: "Массаж всех отделов позвоночника + ягодичных зон (50минут)",
    price: "1800 ₽",
  },
  {
    name: "Релаксирующий английский массаж по аромамаслу (1час 30минут)",
    price: "3000 ₽",
  },
  {
    name: "Подводный общеоздоровительный массаж (25минут)",
    price: "700 ₽",
  },
  {
    name: "Кедровая фито-бочка",
    price: "700 ₽",
  },
];
