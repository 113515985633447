export const Footer = () => {
  return (
    <section
      datatype="footer-customer"
      id="footer-customer-2"
      className="s-footer-customer-type-5 sb-text-white sb-padding-top_l sb-padding-bottom_l"
    >
      <div className=" sb-background" style={{ background: "#111111" }}></div>
      <div className="sb-container">
        <div className="sb-row sb-m-clear-bottom">
          <div className="s-footer-customer-type-5__logo-column sb-col_xs-12 sb-col_sm-12 sb-col_md-12 sb-m-clear-bottom   sb-col_lg-4  ">
            <div className="sb-text-opacity sb-font-p3">
              <b>Студия массажа Александра Стрельцова</b> | Массаж |
              Косметология<div>Коррекция фигуры</div>
            </div>
          </div>
          <div className="s-footer-customer-type-5__links sb-col_xs-12 sb-col_sm-12   sb-col_md-8 sb-col_lg-5  ">
            <nav className="sb-row s-footer-customer-type-5__links-row">
              <ul className="sb-col_xs-12 sb-col_sm-5 sb-col_md-6 sb-col_lg-5">
                <li className="s-footer-customer-type-5__link-wrapper">
                  <a
                    href="#services"
                    className="s-footer-customer-type-5__link sb-font-p3"
                  >
                    Услуги
                  </a>
                </li>
                <li className="s-footer-customer-type-5__link-wrapper">
                  <a
                    href="#product-list"
                    className="s-footer-customer-type-5__link sb-font-p3"
                  >
                    Цены
                  </a>
                </li>
                <li className="s-footer-customer-type-5__link-wrapper">
                  <a
                    href="#reviews-2"
                    className="s-footer-customer-type-5__link sb-font-p3"
                  >
                    Отзывы
                  </a>
                </li>
                <li className="s-footer-customer-type-5__link-wrapper">
                  <a
                    href="#gallery"
                    className="s-footer-customer-type-5__link sb-font-p3"
                  >
                    Фото
                  </a>
                </li>
                <li className="s-footer-customer-type-5__link-wrapper">
                  <a
                    href="#contacts-extensible"
                    className="s-footer-customer-type-5__link sb-font-p3"
                  >
                    Контакты
                  </a>
                </li>
              </ul>
              {/*<ul className="sb-col_xs-12 sb-col_sm-5 sb-col_md-6 sb-col_lg-7">*/}
              {/*  <li className="s-footer-customer-type-5__link-wrapper">*/}
              {/*    <a*/}
              {/*      href="#reviews"*/}
              {/*      className="s-footer-customer-type-5__link sb-font-p3"*/}
              {/*    >*/}
              {/*      Специалисты*/}
              {/*    </a>*/}
              {/*  </li>*/}
              {/*  <li className="s-footer-customer-type-5__link-wrapper">*/}
              {/*    <a*/}
              {/*      href="https://wa.me/77073325381"*/}
              {/*      className="s-footer-customer-type-5__link sb-font-p3"*/}
              {/*    >*/}
              {/*      Создание сайта*/}
              {/*    </a>*/}
              {/*  </li>*/}
              {/*</ul>*/}
            </nav>
          </div>
          <div className="sb-col_xs-12 sb-col_sm-12  sb-col_md-4 sb-col_lg-3 ">
            <div className="sb-align-start sb-font-p3">
              <div>
                <b>Телефон:</b>
              </div>
              <b>8 (968) 933-33-03</b>
              <br />
              Пон-Пятн 9:00-19:00,
              <div>
                Субб 9:00-15:00
                <br />
                <div>Вскр-выходной</div>
                <div>
                  <a
                    data-link-id="0"
                    className="sb-link"
                    href="mailto:s9689333303@mail.ru"
                  >
                    s9689333303@mail.ru
                  </a>
                </div>
                <div>
                  <br />
                  <b>Адрес студии:</b>
                  <br />
                  Московская область, <div>г.Наро-Фоминск, </div>
                  <div>Пионерский пер.,2 </div>
                </div>
              </div>
              <div>Студия красоты и здоровья А.Стрельцова</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
