export const Socials = () => {
  return (
    <section
      datatype="header-socials"
      id="header-socials"
      className="s-header-socials-type-1 sb-text-dark"
    >
      <div className="sb-background" style={{ background: "#FFFFFF" }}></div>
      <div className="sb-container">
        <div className="s-header-socials-type-1__wrapper">
          <nav className="s-header-socials-type-1__socials">
            <a
              className="s-header-socials-type-1__social-item sb-color-border"
              href="https://vk.com/id333163761"
              aria-label="Вконтакте"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="social_ic-vk_x24">
                  <path
                    id="ic-vk"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M17.7703 13.3585C18.301 13.9307 18.8612 14.4692 19.3372 15.0992C19.5475 15.3791 19.7466 15.668 19.8989 15.9929C20.1147 16.4548 19.9192 16.963 19.5442 16.9906L17.2133 16.9894C16.6121 17.0445 16.1325 16.7772 15.7293 16.3233C15.4066 15.9602 15.1077 15.5739 14.7974 15.1986C14.6702 15.0452 14.537 14.9008 14.3779 14.7868C14.0597 14.5586 13.7835 14.6285 13.6017 14.995C13.4165 15.3678 13.3745 15.7806 13.3563 16.1961C13.3313 16.8023 13.1654 16.9616 12.6141 16.9894C11.4358 17.0507 10.3175 16.8539 9.2787 16.1974C8.36284 15.6187 7.65263 14.8017 7.03447 13.8767C5.83089 12.0757 4.90919 10.0967 4.08081 8.06218C3.89434 7.60381 4.03071 7.35776 4.48864 7.34905C5.24905 7.33273 6.00936 7.33389 6.77067 7.34789C7.07973 7.35288 7.28433 7.54865 7.40366 7.87111C7.81507 8.98833 8.31847 10.0513 8.95036 11.0365C9.11863 11.2988 9.29022 11.5611 9.53456 11.7458C9.80487 11.9502 10.0107 11.8824 10.1378 11.5499C10.2185 11.339 10.2539 11.1119 10.2721 10.886C10.3323 10.109 10.3402 9.33337 10.2346 8.5591C10.1697 8.07588 9.92326 7.76306 9.48667 7.67162C9.26388 7.625 9.29705 7.53344 9.40492 7.393C9.59228 7.15078 9.76849 7 10.1198 7H12.7541C13.1688 7.0904 13.2609 7.29616 13.3177 7.75703L13.3199 10.9887C13.3154 11.1672 13.4006 11.6966 13.6916 11.8147C13.9245 11.8987 14.078 11.693 14.2177 11.5299C14.8484 10.7906 15.2986 9.91686 15.7007 9.01214C15.8792 8.61432 16.0327 8.20117 16.1814 7.78838C16.2916 7.48206 16.4645 7.33134 16.7769 7.33802L19.3122 7.34051C19.3874 7.34051 19.4635 7.34173 19.5363 7.35549C19.9635 7.4359 20.0806 7.63888 19.9486 8.09963C19.7407 8.82252 19.3362 9.42493 18.9407 10.0301C18.5179 10.6763 18.0657 11.3003 17.6464 11.9502C17.2611 12.5438 17.2917 12.843 17.7703 13.3585V13.3585Z"
                    fill="currentColor"
                  ></path>
                </g>
              </svg>
            </a>
            <a
              className="s-header-socials-type-1__social-item sb-color-border"
              href="https://wa.me/79689333303"
              aria-label="Whatsapp"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="social_ic-whatsapp_x16">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.14201 0C12.4749 0 16 3.49891 16 7.79964C16 12.0999 12.4749 15.5984 8.14207 15.5984C6.84253 15.5984 5.55848 15.278 4.42238 14.671L0.270689 15.9902C0.250014 15.9968 0.228819 16 0.20778 16C0.152871 16 0.0991049 15.9782 0.0594685 15.9377C0.00455945 15.8818 -0.014142 15.7997 0.0110009 15.7255L1.36077 11.7439C0.655572 10.5523 0.283313 9.19065 0.283313 7.79964C0.283313 3.49891 3.80871 0 8.14201 0ZM11.8392 9.19382C11.808 9.17886 10.6415 8.60447 10.4343 8.52987C10.3497 8.49948 10.259 8.46982 10.1626 8.46982C10.0051 8.46982 9.87277 8.54831 9.7697 8.70249C9.65318 8.87569 9.30046 9.288 9.19147 9.41117C9.17723 9.42743 9.15781 9.44686 9.14617 9.44686C9.13573 9.44686 8.95521 9.37252 8.90056 9.34878C7.64929 8.80525 6.69952 7.49818 6.56929 7.27777C6.55069 7.24608 6.54991 7.23169 6.54975 7.23169C6.55433 7.21491 6.5964 7.17273 6.61812 7.15096C6.68165 7.0881 6.75048 7.00525 6.81708 6.92509C6.84861 6.88712 6.8802 6.84909 6.91121 6.81325C7.00783 6.70083 7.05085 6.61356 7.10072 6.51247L7.12684 6.45995C7.24861 6.21803 7.14461 6.01387 7.111 5.94795C7.08342 5.89278 6.5909 4.7041 6.53853 4.57922C6.41261 4.27787 6.24622 4.13756 6.015 4.13756C5.99355 4.13756 6.015 4.13756 5.92503 4.14135C5.81547 4.14597 5.21884 4.22452 4.95505 4.39081C4.67531 4.56717 4.20207 5.12935 4.20207 6.11803C4.20207 7.00784 4.76674 7.848 5.00918 8.16753C5.01521 8.17558 5.02627 8.19195 5.04233 8.21543C5.97079 9.57138 7.12825 10.5763 8.3016 11.045C9.43121 11.4962 9.96612 11.5484 10.2702 11.5484C10.2703 11.5484 10.2703 11.5484 10.2703 11.5484C10.3981 11.5484 10.5004 11.5383 10.5906 11.5295L10.6478 11.524C11.0381 11.4894 11.8956 11.045 12.0907 10.503C12.2444 10.076 12.2849 9.60951 12.1826 9.44021C12.1126 9.32509 11.9919 9.26717 11.8392 9.19382Z"
                    fill="currentColor"
                  ></path>
                </g>
              </svg>
            </a>
            <a
              className="s-header-socials-type-1__social-item sb-color-border"
              href="tel:+79689333303"
              aria-label="  Номер телефона "
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="social_ic-phone_x16">
                  <path
                    d="M14.2772 11.1462L13.1995 11.0709C12.4568 11.0189 11.7453 10.8682 11.0753 10.6336C10.7636 10.5246 10.4205 10.5503 10.1432 10.7297L8.49203 11.7977C6.62665 10.6763 5.15294 8.98667 4.30775 6.97824L5.59449 5.49273C5.81108 5.24242 5.88405 4.90593 5.81895 4.58147C5.68021 3.88885 5.63038 3.16464 5.68222 2.42325L5.75758 1.34559C5.79903 0.752816 5.35014 0.234178 4.5348 0.177163L3.13883 0.0795473C1.94384 -0.00401429 0.913529 0.906931 0.831569 2.10176C0.342767 9.22795 5.71791 15.4113 12.8428 15.9191C14.0374 16.0042 15.0829 15.1105 15.1664 13.9157L15.2795 12.2992C15.3209 11.7064 14.8699 11.1877 14.2772 11.1462Z"
                    fill="currentColor"
                  ></path>
                </g>
              </svg>
            </a>
            <a
              className="s-header-socials-type-1__social-item sb-color-border"
              href="mailto:s9689333303@mail.ru"
              aria-label="  Электронная почта "
            >
              <svg
                width="16"
                height="12"
                viewBox="0 0 16 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="social_ic-email_x16">
                  <path
                    d="M7.21596 5.91784C7.63375 6.19676 8.36681 6.19679 8.78469 5.91781C8.78475 5.91777 8.78485 5.91771 8.78491 5.91768L15.9057 1.16376C15.6722 0.487312 15.0302 0 14.2763 0H1.72423C0.970317 0 0.328343 0.487312 0.0947475 1.16376L7.21574 5.91771C7.21584 5.91777 7.2159 5.91777 7.21596 5.91784Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M9.30464 6.69873C9.30454 6.6988 9.30448 6.69886 9.30442 6.69889C8.93873 6.94302 8.46929 7.06509 8 7.06509C7.53062 7.06509 7.06137 6.94305 6.69565 6.69886C6.69558 6.69883 6.69555 6.6988 6.69549 6.69877L0 2.22888V10.2737C0 11.2256 0.77335 12 1.72395 12H14.2761C15.2267 12 16 11.2256 16 10.2737V2.22888L9.30464 6.69873Z"
                    fill="currentColor"
                  ></path>
                </g>
              </svg>
            </a>
          </nav>
        </div>
      </div>
    </section>
  );
};
