import doc1 from "../assets/doc1.jpeg";
import doc2 from "../assets/doc2.jpeg";
import doc3 from "../assets/doc3.png"; // Добавленная фотография
import { motion } from "framer-motion";

export const Specialists = () => {
  return (
    <section
      data-type="reviews"
      id="reviews"
      className="s-reviews-type-3 sb-text-dark sb-padding-top_l sb-padding-bottom_l"
    >
      <div className=" sb-background" style={{ background: "#fff" }}></div>
      <motion.div
        initial={{ opacity: 0, transform: "translate3d(0, 200px, 0)" }}
        whileInView={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
        transition={{ duration: 0.5 }}
        viewport={{ once: true }}
        className="sb-container sb-m-clear-bottom"
      >
        <div className="sb-row sb-m-6-bottom">
          <h2 className="sb-col_lg-10 sb-col_md-12 sb-col_sm-12 sb-col_xs-12 sb-font-h2 sb-font-title">
            <font style={{ color: "rgb(0,0,0)" }}>
              <b>Наши специалисты</b>
            </font>
          </h2>
        </div>
        <div className="sb-row sb-m-6-top-minus">
          <div className="sb-m-6-top sb-col_lg-6 sb-col_md-6 sb-col_sm-12 sb-col_xs-12">
            <div className="sb-m-clear-bottom">
              <div className="s-reviews-type-3__top sb-m-18-bottom sb-m-clear-bottom sb-m-clear-right">
                <div className="s-reviews-type-3__image-wrapper sb-m-17-bottom">
                  <div className="s-reviews-type-3__image sb-image-square">
                    <img
                      className="sb-image-crop sb-image-crop_loaded lazy"
                      src={doc1}
                      alt="Александр Стрельцов, массаж Наро-Фоминск"
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                </div>
                <div className="sb-m-clear-bottom">
                  <h3 className="sb-font-h4 sb-m-22-bottom">
                    Стрельцов Александр
                  </h3>
                  <div className="sb-font-p3 sb-text-opacity">Массажист</div>
                </div>
              </div>
              <div className="sb-font-p2">
                Закончил Кисловодский Медицинский Колледж, с красным дипломом,
                отделение "Медицинский массаж", практика с 1996 года.
                <div style={{ userSelect: "auto" }}>
                  Повышение квалификации по курсам ЮМЭЙХО, Талассотерапия,
                  Вертеброневрология, курс Остеопатии, Мануальная терапия.
                </div>
              </div>
            </div>
          </div>
          <div className="sb-m-6-top sb-col_lg-6 sb-col_md-6 sb-col_sm-12 sb-col_xs-12">
            <div className="sb-m-clear-bottom">
              <div className="s-reviews-type-3__top sb-m-18-bottom sb-m-clear-bottom sb-m-clear-right">
                <div className="s-reviews-type-3__image-wrapper sb-m-17-bottom">
                  <div className="s-reviews-type-3__image sb-image-square">
                    <img
                      className="sb-image-crop sb-image-crop_loaded lazy"
                      src={doc2}
                      alt="Студия Красоты и Здоровья А.Стрельцова, Буталова Ольга, Косметология"
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                </div>
                <div className="sb-m-clear-bottom">
                  <h3 className="sb-font-h4 sb-m-22-bottom">Буталова Ольга</h3>
                  <div className="sb-font-p3 sb-text-opacity">Косметолог</div>
                </div>
              </div>
              <div className="sb-font-p2">
                Выпускница Наро-Фоминского Медицинского Училища.
                <div style={{ userSelect: "auto" }}>
                  Повышение квалификации по курсу Медсестринская косметология,
                  Массаж в педиатрии (детский массаж)
                </div>
              </div>
            </div>
          </div>
          {/* Новый блок с фотографией и описанием */}
          <div
            className="sb-m-6-top sb-col_lg-6 sb-col_md-6 sb-col_sm-12 sb-col_xs-12"
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <div className="sb-m-clear-bottom">
              <div
                className="s-reviews-type-3__top sb-m-18-bottom sb-m-clear-bottom sb-m-clear-right"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  flexWrap: "nowrap",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <a
                  href="https://spaquatoria.ru/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div
                    className="s-reviews-type-3__image-wrapper sb-m-17-bottom"
                    style={{
                      scale: "1.6",
                      marginTop: "-20px",
                    }}
                  >
                    <div className="s-reviews-type-3__image sb-image-square">
                      <img
                        className="sb-image-crop sb-image-crop_loaded lazy"
                        src={doc3}
                        style={{ objectFit: "cover", display: "inline-block" }}
                        alt="spaquatoria"
                      />
                    </div>
                  </div>
                </a>
                <div className="sb-m-clear-bottom">
                  <h3 className="sb-font-h4 sb-m-22-bottom"></h3>
                  <div
                    className="sb-font-p3 sb-text-opacity"
                    style={{
                      marginTop: "30px",
                      color: "black",
                    }}
                  >
                    Мы работаем на профессиональной косметике SPAQUATORIA
                  </div>
                </div>
              </div>
              <div className="sb-font-p2">
                {/* Ваше дополнительное описание */}
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </section>
  );
};
