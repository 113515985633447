import { useCallback, useEffect, useRef, useState } from "react";
import { useScrollWindowListener } from "./useScrollWindowListener";

export const useScrollTo = (to = 0) => {
  const [pageY] = useScrollWindowListener();
  const scrollRef = useRef(false);
  const [isScroll, setIsScroll] = useState(false);

  const startScroll = useCallback(() => {
    // при повторном нажатии во время скролла не будет подергиваний,
    // которые происходили из-за императивного метода скорлла, протекающего вне обновлений
    // и как следствие isScroll оставался не изменным до его окончания
    if (scrollRef.current) return;
    scrollRef.current = true;
    setIsScroll(true);
    window.scrollTo(0, to);
  }, [scrollRef.current]);

  const handleEvent = (e) => {
    if (e.currentTarget instanceof Window) {
      stopScroll(e.currentTarget);
    }
  };
  const stopScroll = (target) => {
    if (target !== window) return;
    const pointY = target.scrollY + 1;
    window.scrollTo(0, pointY);
    setIsScroll(false);
    scrollRef.current = false;
  };

  useEffect(() => {
    if (isScroll) window.addEventListener("wheel", handleEvent);
    return () => window.removeEventListener("wheel", handleEvent);
  }, [isScroll]);

  useEffect(() => {
    if (isScroll && pageY === to) {
      setIsScroll(false);
      scrollRef.current = false;
    }
  }, [pageY, isScroll]);

  return [startScroll];
};
