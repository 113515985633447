import { useLockBodyScroll } from "../hooks/useLockBodyScroll";

export const Overflow = ({ onClose }) => {
  useLockBodyScroll();
  return (
    <div
      onClick={onClose}
      style={{
        content: "",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 98,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(51,51,51,.8)",
      }}
    />
  );
};
