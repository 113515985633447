export const Header = ({ isOpen, setOpen }) => {
  return (
    <section
      datatype="header-collapsible-menu"
      id="header-collapsible-menu"
      className={`s-header-collapsible-menu-type-1 s-header-collapsible-menu-type-1_published ${
        isOpen ? "s-header-collapsible-menu-type-1_open" : ""
      }`}
    >
      <div className="s-header-collapsible-menu-type-1__top-wrapper sb-text-dark">
        <div
          className=" sb-background sb-background"
          style={{ background: "#ffffff" }}
        ></div>
        <div className="sb-container">
          <div className="s-header-collapsible-menu-type-1__top">
            <div className="s-header-collapsible-menu-type-1__left">
              <a
                href="tel:+79689333303"
                className="s-header-collapsible-menu-type-1__left-link sb-font-p3"
                onClick={() => setOpen(false)}
              >
                8-968-933-33-03
              </a>
              <a
                href="#contacts-extensible"
                className="s-header-collapsible-menu-type-1__left-link sb-font-p3"
                onClick={() => setOpen(false)}
              >
                МО., г.Наро-Фоминск,
                <br /> Пионерский пер., 2
              </a>
            </div>
            <div className="s-header-collapsible-menu-type-1__right sb-hidden-desktop">
              <a
                href="#contacts-extensible"
                className="s-header-collapsible-menu-type-1__right-link sb-font-p3"
                onClick={() => setOpen(false)}
              >
                Пн-Пт 9:00 - 19:00, Субб 9:00-15:00, Вскр - выходной
              </a>
              <div
                className="s-header-collapsible-menu-type-1__burger"
                onClick={() => setOpen(!isOpen)}
              >
                <span className="s-header-collapsible-menu-type-1__burger-line"></span>
              </div>
            </div>
            <div className="s-header-collapsible-menu-type-1__right sb-hidden-desktop-lg-min">
              <div
                className="s-header-collapsible-menu-type-1__burger"
                onClick={() => setOpen(!isOpen)}
              >
                <span className="s-header-collapsible-menu-type-1__burger-line"></span>
              </div>
            </div>
          </div>
          <div className="s-header-collapsible-menu-type-1__divider sb-hidden-desktop ">
            <div
              className=" sb-background sb-background sb-background_clear s-header-collapsible-menu-type-1__background"
              style={{ background: "#d7d7d7" }}
            ></div>
          </div>
          <div className="s-header-collapsible-menu-type-1__divider sb-hidden-desktop-lg-min ">
            <div
              className=" sb-background sb-background sb-background_clear s-header-collapsible-menu-type-1__background"
              style={{ background: "#d7d7d7" }}
            ></div>
          </div>
        </div>
      </div>
      <div className="s-header-collapsible-menu-type-1__menu-wrapper sb-hidden-desktop  sb-text-dark">
        <div
          className=" sb-background sb-background"
          style={{ background: "#ffffff" }}
        ></div>
        <div
          className="s-header-collapsible-menu-type-1__menu-container sb-container"
          style={{ maxHeight: "calc(100vh - 89px)" }}
        >
          <nav className="s-header-collapsible-menu-type-1__menu">
            <ul className="sb-row sb-m-11-top-minus">
              <li className="sb-col_lg-3 sb-col_md-6 sb-col_sm-6 sb-col_xs-12 sb-m-11-top">
                <a
                  href="#services"
                  className="s-header-collapsible-menu-type-1__link sb-font-p1"
                  onClick={() => setOpen(false)}
                >
                  Услуги
                </a>
              </li>
              <li className="sb-col_lg-3 sb-col_md-6 sb-col_sm-6 sb-col_xs-12 sb-m-11-top">
                <a
                  href="#product-list"
                  className="s-header-collapsible-menu-type-1__link sb-font-p1"
                  onClick={() => setOpen(false)}
                >
                  Цены
                </a>
              </li>
              <li className="sb-col_lg-3 sb-col_md-6 sb-col_sm-6 sb-col_xs-12 sb-m-11-top">
                <a
                  href="#reviews-2"
                  className="s-header-collapsible-menu-type-1__link sb-font-p1"
                  onClick={() => setOpen(false)}
                >
                  Отзывы
                </a>
              </li>
              <li className="sb-col_lg-3 sb-col_md-6 sb-col_sm-6 sb-col_xs-12 sb-m-11-top">
                <a
                  href="#gallery"
                  className="s-header-collapsible-menu-type-1__link sb-font-p1"
                  onClick={() => setOpen(false)}
                >
                  Фото
                </a>
              </li>
              <li className="sb-col_lg-3 sb-col_md-6 sb-col_sm-6 sb-col_xs-12 sb-m-11-top">
                <a
                  href="#reviews"
                  className="s-header-collapsible-menu-type-1__link sb-font-p1"
                  onClick={() => setOpen(false)}
                >
                  Специалисты
                </a>
              </li>
              <li className="sb-col_lg-3 sb-col_md-6 sb-col_sm-6 sb-col_xs-12 sb-m-11-top">
                <a
                  href="#contacts-extensible"
                  className="s-header-collapsible-menu-type-1__link sb-font-p1"
                  onClick={() => setOpen(false)}
                >
                  Контакты
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div className="s-header-collapsible-menu-type-1__menu-wrapper sb-hidden-desktop-lg-min  sb-text-dark">
        <div
          className=" sb-background sb-background"
          style={{ background: "#ffffff" }}
        ></div>
        <div
          className="s-header-collapsible-menu-type-1__menu-container sb-container"
          style={{ maxHeight: "calc(100vh - 89px)" }}
        >
          <div className="s-header-collapsible-menu-type-1__menu sb-m-clear-bottom">
            <div className="s-header-collapsible-menu-type-1__menu-top-links">
              <a
                href="tel:+79689333303"
                className="s-header-collapsible-menu-type-1__menu-top-link sb-font-p3"
                onClick={() => setOpen(false)}
              >
                8-968-933-33-03
              </a>
              <a
                href="#contacts-extensible"
                className="s-header-collapsible-menu-type-1__menu-top-link sb-font-p3"
                onClick={() => setOpen(false)}
              >
                МО., г.Наро-Фоминск, Пионерский пер., 2
              </a>
              <a
                href="#contacts-extensible"
                className="s-header-collapsible-menu-type-1__menu-top-link sb-font-p3"
                onClick={() => setOpen(false)}
              >
                Пн-Пт 9:00 - 19:00, Субб 9:00-15:00, Вскр - выходной
              </a>
            </div>
            <ul className="sb-row sb-m-11-top-minus">
              <li className="sb-col_lg-3 sb-col_md-6 sb-col_sm-6 sb-col_xs-12 sb-m-11-top">
                <a
                  href="#services"
                  className="s-header-collapsible-menu-type-1__link sb-font-p1"
                  onClick={() => setOpen(false)}
                >
                  Услуги
                </a>
              </li>
              <li className="sb-col_lg-3 sb-col_md-6 sb-col_sm-6 sb-col_xs-12 sb-m-11-top">
                <a
                  href="#product-list"
                  className="s-header-collapsible-menu-type-1__link sb-font-p1"
                  onClick={() => setOpen(false)}
                >
                  Цены
                </a>
              </li>
              <li className="sb-col_lg-3 sb-col_md-6 sb-col_sm-6 sb-col_xs-12 sb-m-11-top">
                <a
                  href="#reviews-2"
                  className="s-header-collapsible-menu-type-1__link sb-font-p1"
                  onClick={() => setOpen(false)}
                >
                  Отзывы
                </a>
              </li>
              <li className="sb-col_lg-3 sb-col_md-6 sb-col_sm-6 sb-col_xs-12 sb-m-11-top">
                <a
                  href="#gallery"
                  className="s-header-collapsible-menu-type-1__link sb-font-p1"
                  onClick={() => setOpen(false)}
                >
                  Фото
                </a>
              </li>
              <li className="sb-col_lg-3 sb-col_md-6 sb-col_sm-6 sb-col_xs-12 sb-m-11-top">
                <a
                  href="#reviews"
                  className="s-header-collapsible-menu-type-1__link sb-font-p1"
                  onClick={() => setOpen(false)}
                >
                  Специалисты
                </a>
              </li>
              <li className="sb-col_lg-3 sb-col_md-6 sb-col_sm-6 sb-col_xs-12 sb-m-11-top">
                <a
                  href="#contacts-extensible"
                  className="s-header-collapsible-menu-type-1__link sb-font-p1"
                  onClick={() => setOpen(false)}
                >
                  Контакты
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
