import { useEffect, useLayoutEffect } from "react";

export const useLockBodyScroll = (deps) => {
  const isMob =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
  // Get original body overflow
  const originalStyle = window.getComputedStyle(document.body).overflow;

  useLayoutEffect(
    () => {
      if (deps) {
        if (deps.filter((d) => d === true)) {
          if (!isMob) document.body.style.paddingRight = "12px";
          document.body.style.overflow = "hidden";
        } else {
          if (!isMob) document.body.style.paddingRight = "0";
          document.body.style.overflow = originalStyle;
        }
      } else {
        // Prevent scrolling on mount
        if (!isMob) document.body.style.paddingRight = "12px";
        document.body.style.overflow = "hidden";
      }
    },
    deps ? deps : [],
  ); // Empty array ensures effect is only run on mount and unmount
  useEffect(() => {
    return () => {
      if (!isMob) document.body.style.paddingRight = "0";
      document.body.style.overflow = originalStyle;
    };
  }, []);
};
