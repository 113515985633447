import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styles/about-type-2.css";
import "./styles/call-to-action-type-1.css";
import "./styles/contacts-extensible-type-1.css";
import "./styles/footer.css";
import "./styles/footer-customer-type-5.css";
import "./styles/form-type-1.css";
import "./styles/gallery-type-9.css";
import "./styles/header-collapsible-menu-type-1.css";
import "./styles/header-socials-type-1.css";
import "./styles/main-type-5.css";
import "./styles/popups.css";
import "./styles/product-dialog.css";
import "./styles/product-list-type-2.css";
import "./styles/main-type-6.css";
import "./styles/styles-core.css";
import "./styles/reviews-type-3.css";
import "./styles/services-type-1.css";
import "./styles/size-m.css";
import "./styles/styles-helpers.css";
import "./styles/styles.940715d432f6d448c221.bundle.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
