import bg from "../assets/middle-bg.png";
import { motion } from "framer-motion";

export const Request = () => {
  return (
    <section
      datatype="call-to-action"
      id="call-to-action"
      className="s-call-to-action-type-1 sb-text-white sb-align-center sb-padding-top_l sb-padding-bottom_l"
    >
      <div
        className="sb-background-image sb-background"
        style={{
          backgroundImage: `url(${bg})`,
          objectFit: "cover",
        }}
      >
        <div
          className="sb-background-overlay"
          style={{ opacity: "0.45" }}
        ></div>
      </div>
      <motion.div
        initial={{ opacity: 0, transform: "translate3d(0, 200px, 0)" }}
        whileInView={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
        transition={{ duration: 0.5 }}
        viewport={{ once: true }}
        className="sb-container sb-m-clear-bottom"
      >
        <div className="sb-row s-call-to-action-type-1__header sb-m-5-bottom">
          <div className="sb-col_lg-8 sb-col-offset_lg-2 sb-col_md-12 sb-col_sm-12 sb-col_xs-12">
            <h2 className="sb-font-h3 sb-font-title">
              <font style={{ color: "#d7d7d7" }}>
                <br />
                <div>
                  <b>Запишитесь на первую процедуру уже сейчас!</b>
                </div>
              </font>
            </h2>
          </div>
        </div>
        <div className="s-call-to-action-type-1__buttons sb-m-clear-bottom">
          <a
            href="https://wa.me/79689333303"
            className="sb-button-primary sb-button-primary_white s-call-to-action-type-1__button sb-m-20-bottom sb-button-scheme-dark"
            style={{ backgroundColor: "#FFFFFF" }}
          >
            Записаться
          </a>
        </div>
      </motion.div>
    </section>
  );
};
