import image from "../assets/about-bg.jpeg";
import imageCreator from "../assets/aboutcreator.png"
import { motion } from "framer-motion";

export const AboutCreator = () => {
  return (
    <section
      datatype="main"
      id="main"
      className="s-main-type-5 sb-block sb-block_fullscreen s-main-type-5_right s-main-type-5_step-scroll sb-text-dark"
    >
      <div
        className="sb-background sb-background_clear s-main-type-5__content-background"
        style={{ background: "#ffffff" }}
      ></div>
      <div className="s-main-type-5__additional">
        <div className="s-main-type-5__additional-wrapper">
          <div
            className="sb-background-image sb-background sb-background_clear s-main-type-5__background"
            style={{
              backgroundImage: `url(${imageCreator})`,
              objectFit: "cover",
            }}
          >
            <div
              className="sb-background-overlay"
              style={{ opacity: "0.3" }}
            ></div>
          </div>
        </div>
      </div>
      <div className="sb-container s-main-type-5__container sb-m-2-top sb-m-1-bottom">
        <div className="sb-row sb-row_center">
          <div className="sb-col_lg-5 sb-col_md-5 sb-col_sm-12 sb-col_xs-12 sb-m-clear-bottom sb-col-offset_lg-7 sb-col-offset_md-7">
            <motion.div
              initial={{ opacity: 0, transform: "translate3d(200px, 0, 0)" }}
              whileInView={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
            >
              <h3 className="sb-font-h3 sb-font-title sb-m-14-bottom">
                Информация.
              </h3>
            </motion.div>
            <div className="sb-m-8-bottom sb-text-opacity sb-align-left sb-font-p2">
              <motion.div
                initial={{ opacity: 0, transform: "translate3d(200px, 0, 0)" }}
                whileInView={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
                transition={{ duration: 0.5, delay: 0.25 }}
                viewport={{ once: true }}
              >
                <p style={{ color: "#111111" }}>
                  Здравствуйте наш потенциальный клиент.
                  Приглашаем Вас посетить наши процедуры как разовые, так и комплекс процедур.
                  У нас вы сможете получить качественную реабилитацию всего организма.
                  Любители спа процедур смогут в полном объеме насладиться всеми дарами моря, мы вам предложим как классические антицеллюлитные, так и общие оздоровительные обертывания в термоодеяле.
                  А также массаж всего тела по грязям, глинам, водорослям и другим маскам, в основе которых также будут только натуральные, природные компоненты.
                </p>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, transform: "translate3d(200px, 0, 0)" }}
                whileInView={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
                transition={{ duration: 0.5, delay: 0.25 }}
                viewport={{ once: true }}
              >
                <p style={{ color: "#111111" }}>
                  Классический, лечебный, общий оздоровительный массаж в сочетании с обёртываниями или кедровой фитобочкой, даст вам сильнейший реабилитационный эффект всего организма. Данные процедуры наши постоянные клиенты любят дарить своим друзьям и родственникам на всевозможные праздники, приобретая подарочные сертификаты. А Любителям водных процедур мы предлагаем замечательную спа процедуру, подводный массаж в гидромассажной ванне. А также можно принять: хвойные, йода бромные, бишофитные и сильнейшие грязевые ванны - с грязью Тамбуканского озера.
                  Наш косметолог, проведёт полный спектр процедур по лицу. На этой замечательной процедуре вы сможете не только улучшить качество кожи лица, но и получить полнейший релакс, отдохнуть и восстановить своё эмоциональное состояние.
                  Нашим маленьким посетителям мы оказываем услугу - детского массажа. Тёплые и заботливые руки детского массажиста Ольги, устранят различные проблемы со здоровьем малыша, также проведёт профилактический массаж в положенные периоды взросления ребёнка.
                </p>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, transform: "translate3d(200px, 0, 0)" }}
                whileInView={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
                transition={{ duration: 0.5, delay: 0.25 }}
                viewport={{ once: true }}
              >
                <p style={{ color: "#111111" }}>
                  А детям постарше, проведём профилактику или лечение сколиоза позвоночника и других нарушений осанки. Порекомендуем упражнения для укрепления мышечного корсета.
                </p>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, transform: "translate3d(200px, 0, 0)" }}
                whileInView={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
                transition={{ duration: 0.5, delay: 0.25 }}
                viewport={{ once: true }}
              >
                <p style={{ color: "#111111" }}>
                  Различные виды массажа вам проведёт высококвалифицированный массажист с большим стажем работы Александр Стрельцов.
                </p>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, transform: "translate3d(200px, 0, 0)" }}
                whileInView={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
                transition={{ duration: 0.5, delay: 0.25 }}
                viewport={{ once: true }}
              >
                <p style={{ color: "#111111" }}>
                  Нет смысла перечислять все заболевания опорно-двигательного аппарата которые можно было бы пролечить или пройти профилактику в нашей студии.
                  Отдохните и расслабьтесь в нашей уютной студии. Насладитесь процедурами для лица и тела, которые помогут вам восстановить здоровье и эмоциональное состояния. Наши опытные специалисты заботятся о вашем комфорте. Приходите к нам и позвольте себе заслуженный отдых!
                </p>
              </motion.div>
            </div>
            <motion.div
              initial={{ opacity: 0, transform: "translate3d(200px, 0, 0)" }}
              whileInView={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
              transition={{ duration: 0.5, delay: 0.25 }}
              viewport={{ once: true }}
              className="s-main-type-5__buttons"
            >
              <a
                href="https://wa.me/79689333303"
                className="s-main-type-5__button sb-button-primary sb-m-19-bottom sb-button-scheme-white"
                style={{ backgroundColor: "#904B90" }}
              >
                Записаться на пробную процедуру
              </a>
              <a
                href="#services"
                className="s-main-type-5__button sb-button-secondary sb-m-19-bottom sb-button-type_bordered"
                style={{ borderColor: "#904B90" }}
              >
                Узнать подробнее
              </a>
            </motion.div>
          </div>
        </div>
      </div>
      <div className="s-main-type-5__step-scroll-container">
        <div className="sb-container">
          <div className="sb-row">
            <div className="sb-col_lg-5 sb-col_md-5 sb-col_sm-12 sb-col_xs-12 s-main-type-5__step-scroll-wrapper sb-col-offset_lg-7 sb-col-offset_md-7">
              <div className="s-main-type-5__step-scroll s-main-type-5__step-scroll_right">
                <a href="#services" className="step-scroll step-scroll_align_right">
                  <div className="step-scroll-round">
                    <div className="step-scroll-arrow"></div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
