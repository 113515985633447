import classic from "../assets/classic.png";
import g5 from "../assets/g5.png";
import g4 from "../assets/g4.png";
import child from "../assets/child.jpeg";
import { motion } from "framer-motion";
export const MassageTypes = () => {
  return (
    <section
      datatype="services"
      data-subtype="type-1"
      id="services"
      className="s-services-type-1 sb-text-dark sb-padding-top_l sb-padding-bottom_l"
    >
      <div className=" sb-background" style={{ background: "#fff" }}></div>
      <div className="sb-container sb-m-clear-bottom">
        <motion.div
          initial={{ opacity: 0, transform: "translate3d(0, 200px, 0)" }}
          whileInView={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="sb-row sb-m-3-bottom"
        >
          <h2 className="sb-col_lg-10 sb-col_md-12 sb-col_sm-12 sb-col_xs-12 sb-font-h2 sb-font-title">
            <font style={{ color: "rgb(0, 0, 0)" }}>
              <b>Виды массажа</b>
            </font>
          </h2>
        </motion.div>
        <div className="sb-row sb-m-clear-bottom sb-m-3-top-minus">
          <motion.div
            initial={{ opacity: 0, transform: "translate3d(0, 200px, 0)" }}
            whileInView={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="s-services-type-1__item sb-m-3-top  sb-col_lg-3 sb-col_md-6  sb-col_sm-6 sb-col_xs-12 "
          >
            <div className="s-services-type-1__item-content sb-m-clear-top sb-m-clear-bottom">
              <div className="s-services-type-1__info sb-m-15-bottom sb-m-clear-bottom">
                <div className="sb-image-square sb-skeleton__image sb-m-11-bottom ">
                  <img
                    className="sb-image-crop sb-image-crop_loaded lazy"
                    src={classic}
                    alt="Классический Массаж"
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <h3
                  className="sb-font-h4 sb-font-title sb-m-14-bottom sb-pre-wrap sb-skeleton__title"
                  data-goods-field="title"
                >
                  Классический массаж
                </h3>
                <div
                  className="sb-font-p2 sb-text-opacity sb-pre-wrap sb-skeleton__text"
                  data-goods-field="description"
                >
                  Опытный профессионал Александр оздоровит душу и тело.
                </div>
              </div>
              <div className="s-services-type-1__bottom">
                <a
                  href="#product-list"
                  className="s-main-type-6__button sb-button-secondary sb-button-type_bordered"
                  style={{ borderColor: "#000" }}
                >
                  Узнать цены
                </a>
              </div>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, transform: "translate3d(0, 200px, 0)" }}
            whileInView={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="s-services-type-1__item sb-m-3-top  sb-col_lg-3 sb-col_md-6  sb-col_sm-6 sb-col_xs-12 "
          >
            <div className="s-services-type-1__item-content sb-m-clear-top sb-m-clear-bottom">
              <div className="s-services-type-1__info sb-m-15-bottom sb-m-clear-bottom">
                <div className="sb-image-square sb-skeleton__image sb-m-11-bottom ">
                  <img
                    className="sb-image-crop sb-image-crop_loaded lazy"
                    src={child}
                    alt="Детский Массаж"
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <h3
                  className="sb-font-h4 sb-font-title sb-m-14-bottom sb-pre-wrap sb-skeleton__title"
                  data-goods-field="title"
                >
                  Детский массаж
                </h3>
                <div
                  className="sb-font-p2 sb-text-opacity sb-pre-wrap sb-skeleton__text"
                  data-goods-field="description"
                >
                  &nbsp;Профилактика заболеваний и обеспечение гармоничного
                  развития ребенка
                </div>
              </div>
              <div className="s-services-type-1__bottom">
                <a
                  href="#product-list"
                  className="s-main-type-6__button sb-button-secondary sb-button-type_bordered"
                  style={{ borderColor: "#000" }}
                >
                  Узнать цены
                </a>
              </div>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, transform: "translate3d(0, 200px, 0)" }}
            whileInView={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="s-services-type-1__item sb-m-3-top  sb-col_lg-3 sb-col_md-6  sb-col_sm-6 sb-col_xs-12 "
          >
            <div className="s-services-type-1__item-content sb-m-clear-top sb-m-clear-bottom">
              <div className="s-services-type-1__info sb-m-15-bottom sb-m-clear-bottom">
                <div className="sb-image-square sb-skeleton__image sb-m-11-bottom ">
                  <img
                    className="sb-image-crop sb-image-crop_loaded lazy"
                    src={g5}
                    style={{ objectFit: "cover" }}
                    alt="Косметология, Классический Уход по Лицу, Косметический Массаж"
                  />
                </div>
                <h3
                  className="sb-font-h4 sb-font-title sb-m-14-bottom sb-pre-wrap sb-skeleton__title"
                  data-goods-field="title"
                >
                  Косметология<div style={{ userSelect: "auto" }}></div>
                </h3>
                <div
                  className="sb-font-p2 sb-text-opacity sb-pre-wrap sb-skeleton__text"
                  data-goods-field="description"
                >
                  Уход за кожей человека и устранение ее эстетических
                  недостатков
                </div>
              </div>
              <div className="s-services-type-1__bottom">
                <a
                  href="#product-list"
                  className="s-main-type-6__button sb-button-secondary sb-button-type_bordered"
                  style={{ borderColor: "#000" }}
                >
                  Узнать цены
                </a>
              </div>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, transform: "translate3d(0, 200px, 0)" }}
            whileInView={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
            transition={{ duration: 0.5 }}
            viewport={{ once: true }}
            className="s-services-type-1__item sb-m-3-top  sb-col_lg-3 sb-col_md-6  sb-col_sm-6 sb-col_xs-12 "
          >
            <div className="s-services-type-1__item-content sb-m-clear-top sb-m-clear-bottom">
              <div className="s-services-type-1__info sb-m-15-bottom sb-m-clear-bottom">
                <div className="sb-image-square sb-skeleton__image sb-m-11-bottom ">
                  <img
                    className="sb-image-crop sb-image-crop_loaded lazy"
                    src={g4}
                    style={{ objectFit: "cover" }}
                    alt="Коррекция Фигуры, Массаж Воротниковой Зоны, Шейно-Грудного Отдела, Коррекция Фигуры"
                  />
                </div>
                <h3
                  className="sb-font-h4 sb-font-title sb-m-14-bottom sb-pre-wrap sb-skeleton__title"
                  data-goods-field="title"
                >
                  Комплекс услуг по коррекции фигуры
                  <div style={{ userSelect: "auto" }}></div>
                </h3>
                <div
                  className="sb-font-p2 sb-text-opacity sb-pre-wrap sb-skeleton__text"
                  data-goods-field="description"
                >
                  Антицеллюлитные обертывания и массаж
                </div>
              </div>
              <div className="s-services-type-1__bottom">
                <a
                  href="#product-list"
                  className="s-main-type-6__button sb-button-secondary sb-button-type_bordered"
                  style={{ borderColor: "#000" }}
                >
                  Узнать цены
                </a>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};
