import { useScrollTo } from "../hooks/useScrollTo";
import { useState } from "react";
import { useVisibleTopLink } from "../hooks/useVisibleTopLink";
import { ReactComponent as TopArrow } from "../assets/back-to-top.svg";
export const GoToTop = () => {
  const [scroll] = useScrollTo();
  const [isTopLinkVisible, toggleIsTopLinkVisible] = useState(false);
  useVisibleTopLink(isTopLinkVisible, toggleIsTopLinkVisible);

  return (
    <div className="s-back-to-top">
      <div
        className="s-back-to-top__image"
        onClick={scroll}
        style={{ opacity: isTopLinkVisible ? "1" : "0" }}
      >
        <TopArrow />
      </div>
    </div>
  );
};
