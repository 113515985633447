import { motion } from "framer-motion";
import g1 from "../assets/g1.png";
import g2 from "../assets/g2.png";
import g3 from "../assets/g3.png";
import g4 from "../assets/g4.png";
import g5 from "../assets/g5.png";
import g10 from "../assets/g10.png";
import g11 from "../assets/g11.png";
import g12 from "../assets/g12.png";
import g13 from "../assets/g13.png";
import g14 from "../assets/g14.png";
import video from "../assets/IMG_7650.mp4";
import classic from "../assets/classic.png";

export const Gallery = () => {
  return (
    <section
      datatype="gallery"
      id="gallery"
      className="s-gallery-type-9 sb-text-dark sb-padding-top_l sb-padding-bottom_l"
    >
      <div className="sb-background" style={{ background: "#fff" }}></div>
      <div className="sb-container sb-m-clear-bottom">
        <motion.div
          initial={{ opacity: 0, transform: "translate3d(0, 200px, 0)" }}
          whileInView={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
          transition={{ duration: 0.5 }}
          viewport={{ once: true }}
          className="sb-row sb-m-5-bottom sb-m-clear-bottom"
        >
          <h2 className="sb-col_lg-10 sb-col_md-12 sb-col_sm-12 sb-col_xs-12 sb-m-12-bottom sb-font-h2 sb-font-title">
            Галерея
          </h2>
          <div className="sb-col_lg-10 sb-col_md-12 sb-col_sm-12 sb-col_xs-12 sb-font-p3">
            Окунитесь в атмосферу блаженства вместе со Студией красоты и здоровья Александра Стрельцова
          </div>
        </motion.div>
        <div className="s-gallery-type-9__images-wrapper">
          <div className="s-gallery-type-9__images">
            <motion.div
              initial={{ opacity: 0, transform: "translate3d(0, 200px, 0)" }}
              whileInView={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
              className="sb-row s-gallery-type-9__row"
            >
              <div className="sb-col_xs-12 s-gallery-type-9__col sb-col_lg-4 sb-col_md-4 sb-col_sm-4">
                <figure className="s-gallery-type-9__image">
                  <div className="s-gallery-type-9__image-wrapper sb-image-square">
                    <img
                      className="sb-image-crop sb-image-crop_loaded lazy"
                      src={g2}
                      alt="Студия Красоты и Здоровья А.Стрельцова, массаж Пояснично-Крестовой Области, всех отделов позвоночника, Подводный Общеоздоровительный Массаж, Общий Массаж Тела, Релаксирующий Английский Массаж, Наро-Фоминск, Наро-Фоминский район Подмосковья"
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                </figure>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, transform: "translate3d(0, 200px, 0)" }}
              whileInView={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
              className="sb-row s-gallery-type-9__row"
            >
              <div className="sb-col_xs-12 s-gallery-type-9__col sb-col_lg-4 sb-col_md-4 sb-col_sm-4">
                <figure className="s-gallery-type-9__image">
                  <div className="s-gallery-type-9__image-wrapper sb-image-square">
                    <img
                      className="sb-image-crop sb-image-crop_loaded lazy"
                      style={{ objectFit: "cover" }}
                      src={g3}
                      alt="Коррекция Фигуры Парафин Для Рук, массаж по грязям, Антицеллюлитный массаж, ноги, ягодицы, Корректирующий массаж живота,Моделирующий массаж груди, Подводный антицеллюлитный массаж"
                    />
                  </div>
                </figure>
              </div>
              <div className="sb-col_xs-12 s-gallery-type-9__col sb-col_lg-8 sb-col_md-8 sb-col_sm-8">
                <figure className="s-gallery-type-9__image">
                  <div className="s-gallery-type-9__image-wrapper sb-image-rectangle-extra-small">
                    <img
                      className="sb-image-crop sb-image-crop_loaded lazy"
                      style={{ objectFit: "cover" }}
                      src={g4}
                      alt="Общий Массаж Тела, Антицеллюлитный массаж, ноги, ягодицы"
                    />
                  </div>
                </figure>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, transform: "translate3d(0, 200px, 0)" }}
              whileInView={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
              className="sb-row s-gallery-type-9__row"
            >
              <div className="sb-col_xs-12 s-gallery-type-9__col sb-col_lg-8 sb-col_md-8 sb-col_sm-8">
                <figure className="s-gallery-type-9__image">
                  <div className="s-gallery-type-9__image-wrapper sb-image-rectangle-extra-small">
                    <img
                      className="sb-image-crop sb-image-crop_loaded lazy"
                      style={{ objectFit: "cover" }}
                      src={g10}
                      alt="Общий Массаж Тела, Антицеллюлитный массаж, ноги, ягодицы"
                    />
                  </div>
                </figure>
              </div>
              <div className="sb-col_xs-12 s-gallery-type-9__col sb-col_lg-8 sb-col_md-8 sb-col_sm-8">
                <figure className="s-gallery-type-9__image">
                  <div className="s-gallery-type-9__image-wrapper sb-image-rectangle-extra-small">
                    <img
                      className="sb-image-crop sb-image-crop_loaded lazy"
                      style={{ objectFit: "cover" }}
                      src={g11}
                      alt="Общий Массаж Тела, Антицеллюлитный массаж, ноги, ягодицы"
                    />
                  </div>
                </figure>
              </div>
              <div className="sb-col_xs-12 s-gallery-type-9__col sb-col_lg-8 sb-col_md-8 sb-col_sm-8">
                <figure className="s-gallery-type-9__image">
                <div className="s-gallery-type-9__image-wrapper sb-image-square">
                    <img
                      className="sb-image-crop sb-image-crop_loaded lazy"
                      src={g12}
                      alt="Студия Красоты и Здоровья А.Стрельцова, массаж Пояснично-Крестовой Области, всех отделов позвоночника, Подводный Общеоздоровительный Массаж, Общий Массаж Тела, Релаксирующий Английский Массаж, Наро-Фоминск, Наро-Фоминский район Подмосковья"
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                </figure>
              </div>
              <div className="sb-col_xs-12 s-gallery-type-9__col sb-col_lg-8 sb-col_md-8 sb-col_sm-8">
                <figure className="s-gallery-type-9__image">
                <div className="s-gallery-type-9__image-wrapper sb-image-square">
                    <img
                      className="sb-image-crop sb-image-crop_loaded lazy"
                      src={g13}
                      alt="Студия Красоты и Здоровья А.Стрельцова, массаж Пояснично-Крестовой Области, всех отделов позвоночника, Подводный Общеоздоровительный Массаж, Общий Массаж Тела, Релаксирующий Английский Массаж, Наро-Фоминск, Наро-Фоминский район Подмосковья"
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                </figure>
              </div>
              <div className="sb-col_xs-12 s-gallery-type-9__col sb-col_lg-8 sb-col_md-8 sb-col_sm-8">
                <figure className="s-gallery-type-9__image">
                  <div className="s-gallery-type-9__image-wrapper sb-image-rectangle-extra-small">
                    <img
                      className="sb-image-crop sb-image-crop_loaded lazy"
                      style={{ objectFit: "cover" }}
                      src={g14}
                      alt="Общий Массаж Тела, Антицеллюлитный массаж, ноги, ягодицы"
                    />
                  </div>
                </figure>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, transform: "translate3d(0, 200px, 0)" }}
              whileInView={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
              className="sb-row s-gallery-type-9__row"
            >
              <div className="sb-col_xs-12 s-gallery-type-9__col sb-col_lg-8 sb-col_md-8 sb-col_sm-8">
                <figure className="s-gallery-type-9__image">
                  <div className="s-gallery-type-9__image-wrapper sb-image-rectangle-extra-small">
                    <img
                      className="sb-image-crop sb-image-crop_loaded lazy"
                      style={{ objectFit: "cover" }}
                      src={g5}
                      alt="Косметология, Классический Уход по Лицу, Парафин Для Рук, Косметический Массаж"
                    />
                  </div>
                </figure>
              </div>
              <div className="sb-col_xs-12 s-gallery-type-9__col sb-col_lg-4 sb-col_md-4 sb-col_sm-4">
                <figure className="s-gallery-type-9__image">
                  <div className="s-gallery-type-9__image-wrapper sb-image-square">
                    <img
                      className="sb-image-crop sb-image-crop_loaded lazy"
                      style={{ objectFit: "cover" }}
                      src={classic}
                      alt="Общий Массаж Тела, Моделирующий массаж груди"
                    />
                  </div>
                </figure>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, transform: "translate3d(0, 100px, 0)" }}
              whileInView={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
              transition={{ duration: 0.5 }}
              viewport={{ once: true }}
              className="sb-row s-gallery-type-9__row"
            >
              <div className="sb-col_xs-12 s-gallery-type-9__col sb-col_lg-8 sb-col_md-8 sb-col_sm-8">
                <figure className="s-gallery-type-9__image">
                  <div
                    className="s-gallery-type-9__image-wrapper sb-image-rectangle-extra-small"
                    style={{ paddingTop: "70.77%" }}
                  >
                    <video
                      className="sb-image-crop sb-image-crop_loaded lazy"
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "100%",
                      }}
                      controls
                      alt="Косметология, Классический Уход по Лицу, Парафин Для Рук, Косметический Массаж"
                    >
                      <source src={video} type="video/mp4" />
                    </video>
                  </div>
                </figure>
              </div>
              <div className="sb-col_xs-12 s-gallery-type-9__col sb-col_lg-4 sb-col_md-4 sb-col_sm-4">
                <figure className="s-gallery-type-9__image">
                  <div className="s-gallery-type-9__image-wrapper sb-image-square"></div>
                </figure>
              </div>
              <div className="sb-col_xs-12 s-gallery-type-9__col sb-col_lg-8 sb-col_md-8 sb-col_sm-8">
                <div className="your-text-container"></div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};
