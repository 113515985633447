import { motion } from "framer-motion";
import useWindowWidth from "../hooks/useWindowWidth";
import banner from "../assets/main-bg.png";
export const Banner = ({ isOpen }) => {
  const width = useWindowWidth();
  const margin = width > 1359 ? "119px" : width > 639 ? "89px" : "65px";
  return (
    <section
      datatype="main"
      id="main-4"
      className="s-main-type-6 sb-block sb-block_fullscreen s-main-type-6_with-scroll-icon sb-text-white"
      style={isOpen ? { marginTop: margin } : undefined}
    >
      <div
        className="sb-background-image sb-background sb-background"
        style={{
          backgroundImage: `url(${banner})`,
          objectFit: "cover",
        }}
      >
        <div
          className="sb-background-overlay"
          style={{ opacity: "0.32" }}
        ></div>
      </div>
      <div className="s-main-type-6__wave-container">
        <div
          className=" sb-background sb-background sb-background_clear s-main-type-6__wave-editor"
          style={{ background: "#fff" }}
        ></div>
        <div className="s-main-type-6__wave">
          <svg
            className="s-main-type-6__wave-icon"
            width="2536"
            height="85"
            viewBox="0 0 2536 85"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 5.24508C0 5.24508 383.286 56.6199 634 56.6199C884.714 56.6199 1017.29 1.01629 1268 0.031717C1518.12 -0.950562 1651.87 51.4065 1902 51.4065C2152.13 51.4065 2536 0.031717 2536 0.031717V87.0003H0V5.24508Z"
              fill="#fff"
            />
          </svg>
        </div>
      </div>
      <div className="sb-container sb-m-clear-top">
        <div className="sb-row ">
          <div className="sb-col_lg-8 sb-col_md-12 sb-col_sm-12 sb-col_xs-12 sb-m-clear-top">
            <div className="sb-m-clear-bottom">
              <motion.div
                initial={{ opacity: 0, transform: "translate3d(-200px, 0, 0)" }}
                animate={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
                transition={{ duration: 0.5 }}
              >
                <h1 className="sb-font-h1 sb-font-title sb-m-9-bottom">
                  Cтудия массажа
                  <div>Александра Стрельцова</div>
                </h1>
              </motion.div>
              <div className="sb-text-opacity sb-font-p2">
                <motion.div
                  initial={{
                    opacity: 0,
                    transform: "translate3d(-200px, 0, 0)",
                  }}
                  animate={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
                  transition={{ duration: 0.5, delay: 0.25 }}
                >
                  {/*<font color="#ffffff">*/}
                  {/*  Оставьте заявку сейчас и начните получать удовольствие уже*/}
                  {/*  завтра от профессионального массажиста с 23 летним стажем*/}
                  {/*</font>*/}
                </motion.div>
                <br />
              </div>
            </div>
            <motion.div
              className="s-main-type-6__buttons sb-m-6-top"
              initial={{
                opacity: 0,
                transform: "translate3d(-200px, 0, 0)",
              }}
              animate={{ opacity: 1, transform: "translate3d(0, 0, 0)" }}
              transition={{ duration: 0.5, delay: 0.5 }}
            >
              {/*<a*/}
              {/*  href="https://wa.me/79689333303"*/}
              {/*  className="s-main-type-6__button sb-button-primary"*/}
              {/*>*/}
              {/*  Оставить заявку*/}
              {/*</a>*/}
              <a
                href="#product-list"
                className="s-main-type-6__button sb-button-secondary sb-button-type_bordered"
              >
                Узнать цены
              </a>
            </motion.div>
          </div>
        </div>
      </div>
      <a data-step-scroll="" className="step-scroll" href={"#about"}>
        <div className="step-scroll-round">
          <div className="step-scroll-arrow"></div>
        </div>
      </a>
    </section>
  );
};
