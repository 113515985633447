export const cosmeticData = [
  {
    name: "Классический уход по лицу (очищение, массаж, маска). Время процедуры 1час 10минут",
    price: "2000 ₽",
  },
  {
    name: "Косметический массаж. Время процедуры 40 мин",
    price: "1000 ₽",
  },
  {
    name: "Парафин для рук",
    price: "250 ₽",
  },
  {
    name: "У нас вы можете приобрести подарочный сертификат",
    price: "",
  },
];
