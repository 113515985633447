import { cosmeticData } from "../data/cosmetic";

export const Cosmetic = () => {
  return (
    <section
      datatype="product-list"
      data-subtype="type-2"
      id="product-list-3"
      className="s-product-list-type-2 sb-text-dark sb-padding-top_l sb-padding-bottom_l"
    >
      <div className=" sb-background" style={{ background: "#fff" }}></div>
      <div className="sb-container sb-m-clear-bottom">
        <div className="sb-row sb-m-5-bottom">
          <h2 className="sb-col_lg-10 sb-col_md-12 sb-col_sm-12 sb-col_xs-12 sb-col_md-12 sb-col_sm-12 sb-col_xs-12 sb-font-h2 sb-font-title">
            <font style={{ color: "rgb(0,0,0)" }}>
              <b>Косметология</b>
            </font>
          </h2>
        </div>
        <div className="s-product-list-type-2__list">
          <div className="sb-row">
            {cosmeticData.map(({ name, price }, i) => (
              <div
                key={i}
                className="sb-col_lg-6 sb-col_md-6 sb-col_sm-12 sb-col_xs-12 "
              >
                <div className="s-product-list-type-2__item">
                  <div className="s-product-list-type-2__item-content">
                    <div className="s-product-list-type-2__info">
                      <div
                        className="sb-font-h4 sb-font-title sb-m-18-bottom sb-pre-wrap sb-skeleton__title"
                        data-goods-field="title"
                      >
                        {name}
                      </div>
                    </div>
                    <div
                      className="s-product-list-type-2__price sb-font-h4 sb-font-title sb-skeleton__price"
                      data-goods-field="priceFull"
                    >
                      {price}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
